// Routing 페이지의 유효성 검사 함수

type PageType =
  | "items"
  | "equipment"
  | "defects"
  | "operations"
  | "routing"
  | "users"
  | "site"
  | "location"
  | "routingOutsource"
  | "inspectSpecification"
  | "mold"
  | "moldStock"
  | "abstractItem"
  | "itemCategory"
  | "downTimeReasons";

interface RoutingFormValues {
  pageType: PageType;
  values: any;
}

// 페이지별 유효성 검사
export function validateFormValues(params: RoutingFormValues) {
  const { pageType, values } = params;

  switch (pageType) {
    case "items":
      return !(values.itemCode && values.code);
    case "equipment":
      return !(values.siteCode && values.code);
    case "defects":
      return !(values.code && values.name);
    case "operations":
      return !(
        values.siteCode &&
        values.code &&
        values.fromLocationCode &&
        values.toLocationCode
      );
    case "routing":
      return !(
        values.itemCode &&
        values.operationCode &&
        values.code &&
        values.routingType &&
        values.consumeType &&
        values.seq
      );
    case "users":
      return !(values.code && values.name && values.username);
    case "site":
      return !(values.code && values.name);
    case "location":
      return !(values.code && values.name && values.siteCode);
    case "routingOutsource":
      return !(
        values.name &&
        values.routingCode &&
        values.fromLocationCode &&
        values.toLocationCode
      );
    case "inspectSpecification":
      return !(values.itemCode && values.criteria);
    case "mold":
      return !values.code;
    case "moldStock":
      return !(values.code && values.moldCode);
    case "abstractItem":
      return !values.code;
    case "itemCategory":
      return !values.name;
    case "downTimeReasons":
      return !(values.code && values.name);
  }
}
