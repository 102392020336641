import styled from "@emotion/styled";
import { NumberInput } from "@mantine/core";

export interface BoxNumberInputProps
  extends React.ComponentProps<typeof NumberInput> { }

export const BoxNumberInput = (params: BoxNumberInputProps) => {
  const { ...etcParams } = params;

  return (
    <Input type="number" {...etcParams}></Input>
  );
};

const Input = styled(NumberInput)`
.mantine-Input-rightSection {
  width : 3.75rem !important;
}
  .mantine-NumberInput-controlUp, .mantine-NumberInput-controlDown {
    min-width : 4rem !important;
    min-height : 50% !important;
    width: 2rem !important;
    height: 50% !important;
    &>svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
`

