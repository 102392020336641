import { ScrollArea, TableProps } from "@mantine/core";
import { TableData } from "./tableData";
import { TableHeader } from "./tableHeader";
import { TableMain } from "./tableMain";
import { TableRow } from "./tableRow";

interface WorkTableProps extends TableProps {
  columns: (string | JSX.Element)[];
  data: (string | JSX.Element)[][] | undefined;
  columnHeight?: string;
  rowHeight?: string;
  theadColor?: string;
  trColor?: string;
  flex?: number[];
}

const Table = Object.assign(TableMain, {
  Th: TableHeader,
  Td: TableData,
  Tr: TableRow,
});

export const WorkTable = (params: WorkTableProps) => {
  const {
    columns,
    data,
    columnHeight,
    rowHeight,
    theadColor,
    trColor,
    flex,
    ...etcParmas
  } = params;

  return (
    <ScrollArea
      w={"100%"}
      h={300}
      styles={(theme) => ({
        scrollbar: {
          '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
            backgroundColor: theme.colors.gray[4],
          },
        },
      })}
    >
      <Table {...etcParmas}>
        <thead
          style={{
            height: columnHeight,
            position: "sticky",
            top: "0",
            background: theadColor || "#161B21",
            zIndex: 1,
          }}
        >
          <Table.Tr>
            {columns.map((column, index) => {
              const flexValue = flex && flex[index];
              return <Table.Th flex={flexValue}>{column}</Table.Th>;
            })}
          </Table.Tr>
        </thead>

        <tbody style={{ height: rowHeight }}>
          {data?.map((row) => {
            return (
              <Table.Tr thColor={trColor}>
                {row.map((data, index) => {
                  const flexValue = flex && flex[index];
                  return <Table.Td flex={flexValue}>{data}</Table.Td>;
                })}
              </Table.Tr>
            );
          })}
        </tbody>
      </Table>
    </ScrollArea>
  );
};
