import Footer from "layouts/common/footer";
import Sidebar from "layouts/common/sidebar";
import React from "react";
import { Outlet } from "react-router-dom";

const Layout: React.FC = () => {
    return (
        <div className="App">
            <div className="container">
                <div className="grid-container">
                    <Sidebar />
                    <div className="content">
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Layout;
