import { Title, TitleProps } from "@mantine/core";

export const BaseTitle = (params: TitleProps) => {

    const { children } = params;

    return (
        <Title
            fz={'1.25rem'}
            fw={700}
            lh={1.55}
            c={'gray.6'}
            fs={'normal'}
            ff={'Noto Sans'}
            {...params}
        >
            {children}
        </Title>
    );
};
