import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import useOperationsPostQuery from "api/operations/useOperationPostQuery";

import { RoutingOperationForm } from "components/common/form/routings/Routingsform";
import { useModal } from "context/ModalStackManager";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import {
  OperationOption,
  useOperationsAutoComplete,
  useOperationsCode,
} from "./auto-useGetOperationsQuery";

interface OperationProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface OperationAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

export const OperationAutoComplete = (params: OperationAutoCompleteProps) => {
  const {
    value: operationCode,
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const { data: options } = useOperationsAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useOperationsCode(
    !!operationCode,
    operationCode ?? null
  );

  let selectedOperate = initialOptions?.find((item) => {
    return item.value === operationCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedOperate = options?.find((item) => item.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, OperationProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{code}</Text>
            <Text fz="xs"> (name: {name === "null" ? "-" : name})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <OperationSelect
      styles={{ input: { width: width } }}
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedOperate?.value && (
            <div
              style={{
                position: "absolute",
                fontSize: "0.5em",
                color: "#888",
              }}
            >
              {"Code: " + selectedOperate?.value}
            </div>
          )}
        </div>
      )}
      value={operationCode}
      itemComponent={SelectItem}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: OperationProps[], option: OperationProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={OperationInfo({
        operate: selectedOperate as OperationOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewOperation({
        operateCode: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const OperationInfo = (params: {
  operate?: OperationOption;
  onChange: (operationCode: string | null) => void;
}) => {
  const { operate, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return operate?.value ? (
    <OperationInfoLabel>
      {/* ({operate.value})  */}
      <IconX size="1rem" onClick={clearHandler} />
    </OperationInfoLabel>
  ) : null;
};

const AddNewOperation = (params: {
  operateCode: string;
  onChange: (itemCode: string) => void;
}) => {
  const { operateCode, onChange } = params;
  const { openModal, closeModal } = useModal();
  const { mutate: postMutate } = useOperationsPostQuery("create");

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate(
      {
        operationsGetRequest: {
          code: values.code,
          name: values.name,
          siteCode: values.siteCode,
          fromLocationCode: values.fromLocationCode,
          toLocationCode: values.toLocationCode,
        },
      },
      {
        onSuccess: () => {
          alert("공정 생성 성공");
          closeModal(values);
        },
      }
    );
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <RoutingOperationForm code={operateCode} onSubmit={onCloseHandler} />,
      "",
      "신규 공정 등록"
    ).then((result) => {
      onChange(result.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 공정({operateCode}) 등록
    </Button>
  );
};
const OperationInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const OperationSelect = styled(Select)`
  width: 100%;
`;
