// MyAccordionComponent.tsx

import { Accordion } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { theme } from "styles/theme";


interface WorkAccordionProps {
    children: React.ReactNode
}


const WorkAccordion = ({ children }: WorkAccordionProps) => {
    return (
        <Accordion
            w={"100%"}
            chevron={<IconPlus size="2.5rem" />}
            styles={
                {
                    item: {
                        border: `1px solid ${theme?.colors?.gray?.[7]}`,
                    },
                    control: {
                        ":hover": { backgroundColor: `${theme?.colors?.gray?.[7]}` },
                    },
                    chevron: {
                        color: "white",
                        '&[data-rotate]': {
                            transform: "rotate(45deg)"
                        },
                    }
                }
            } // 스타일 객체를 함수 호출로 전달
        >
            {children}
        </Accordion>
    );
};

export default WorkAccordion;