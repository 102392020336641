import { useLocalStorage } from 'context/LocalStorageProvider';
import ProtectedRoute from 'context/ProtectedRoute';
import Layout from 'layouts/Layout';
import Auth from 'pages/auth';
import { Inspections } from 'pages/common/inspections';
import { Inventory } from 'pages/common/inventory';
import { WarningPopPageInfo } from 'pages/common/warning/warningPopPageInfo';
import WorkByRowPageByCompanies from 'pages/common/workByRowPageByCompanies';
import { Works } from 'pages/common/works';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useCheckAuth } from 'utils/checkAuth';


function Index() {
  const { workId } = useLocalStorage();
  const auth = useCheckAuth();

  return (
    <Routes>
      <Route path="/"
        element={
          <div className="App">
            <div className="container">
              <div className="unauth-container"><Outlet /></div>
            </div>
          </div>
        }
      >
        <Route path="/" element={auth ? <Navigate to="/works" /> : <Auth />} />
      </Route>

      <Route path="/" element={<Layout />}>
        <Route path="/works" element={<ProtectedRoute redirectCondition={!auth} redirectPath="/" />}>
          <Route path="/works" element={<Works />} />
        </Route>
        <Route path="/work/:workId" element={<ProtectedRoute redirectCondition={!auth} redirectPath="/" />}>
          <Route path="/work/:workId" element={workId ? <WorkByRowPageByCompanies /> : <WarningPopPageInfo />} />
        </Route>
        <Route path="/inventory" element={<ProtectedRoute redirectCondition={!auth} redirectPath="/" />}>
          <Route path="/inventory" element={<Inventory />} />
        </Route>
        <Route path="/inspections" element={<ProtectedRoute redirectCondition={!auth} redirectPath="/" />}>
          <Route path="/inspections" element={<Inspections />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Index;

