import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import useDowntimeReasonsMutationQuery from "api/downtimeReasons/useDowntimeReasonsMutationQuery";
import { OperationAutoComplete } from "components/common/autoComplete/operation/operation-autoComplete";

import { useModal } from "context/ModalStackManager";
import { validateFormValues } from "utils/validation";
import { FormButtonBox } from "../FormButtonBox";
import { FormMain } from "../FormMain";
import { FormWrapper } from "../FormWrapper";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const DowntimeReasonsCreateForm = (params: {
  name: string;
  onSubmit?: (values: any) => void;
}) => {
  const { name } = params;
  const { closeModal } = useModal();
  const { mutate: createMutate } = useDowntimeReasonsMutationQuery("create");

  const form = useForm({
    initialValues: {
      name: name,
      code: undefined,
      operationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          downtimeReasonsGetRequest: {
            name: name!,
            code: form.values.code!,
            operationCode: form.values.operationCode!,
          },
        },
        {
          onSuccess: () => {
            alert("비가동사유 생성 성공");
            closeModal(form.values);
          },
        }
      );
    } catch (e) {
      alert("실패");
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input
        size="xl"
        withAsterisk
        label="비가동사유 코드"
        {...form.getInputProps("code")}
      />
      <Create.Input
        size="xl"
        withAsterisk
        label="비가동사유명 "
        {...form.getInputProps("name")}
      />
      <OperationAutoComplete
        size="xl"
        label="공정 코드"
        {...form.getInputProps("operationCode")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" size="xl" onClick={closeModal} className="cancel-btn">
          취소
        </Create.Button>
        <Create.Button
          onClick={onSubmit}
          size="xl"
          disabled={validateFormValues({
            pageType: "downTimeReasons",
            values: form.values,
          })}
        >
          저장
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
