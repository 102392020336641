import { Text, TextProps } from "@mantine/core";

export const BaseText = (params: TextProps) => {

    const { children } = params;

    return (
        <Text
            color={'white.0'}
            fz={'1.25rem'}
            fs={'normal'}
            fw={700}
            lh={1.55}
            {...params}
        >
            {children}
        </Text>
    );
};
