import { equipments } from 'api/equipmentCount/useEquipmentQuery';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import io from 'socket.io-client';

type Props = {
    equipmentCode: string;
    enabled: boolean;
    equipmentId: number;
    onConnected?: () => void;
};

type Message = {
    equipmentCounter: string
    equipmentLimitCounter: string
    isLimitHit: boolean
};

export const useSocketIo = ({ equipmentCode, enabled, onConnected, equipmentId }: Props) => {

    const ref = useRef<any>();
    const [messages, setMessages] = useState<Message[]>([]);

    const { refetch } = useQuery({
        ...equipments.detail({
            equipmentId: equipmentId
        }), enabled: !!equipmentId && enabled,
        onSuccess: (res) =>
            setMessages((prev: any) =>
                [{ ...prev[0], equipmentCounter: res.data.equipmentCounter }])
    });

    useEffect(() => {

        const roomName = equipmentCode ? `EC_${equipmentCode}` : null;

        if (!enabled || !equipmentCode || !roomName) {
            setMessages([]);
            console.log('WebSocket 비활성화됨');
            return;
        }

        const socket = io(process.env.REACT_APP_BASE_URL as string);

        const joinRoom = () => {
            socket.emit("call", "socket.join", { room: roomName });
        };

        socket.on(roomName, (msg: Message) => {
            console.log('메시지 수신:', msg);
            setMessages((prev) => prev.concat(msg));
        });

        socket.on('connect', () => {
            console.log('WebSocket에 연결됨');
            joinRoom();
            if (onConnected) {
                onConnected();
            }
        });

        socket.on('reconnect', () => {
            console.log('WebSocket에 재연결됨');
            joinRoom();
        });

        socket.on('disconnect', () => {
            console.log('WebSocket 연결이 끊어짐');
        });

        ref.current = socket;

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                console.log('App이 다시 활성화됨 - WebSocket 재연결 시도');
                socket.connect(); // Attempt to reconnect the socket
                refetch();
            } else {
                console.log('App이 비활성화됨 - WebSocket 연결 해제');
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            console.log('WebSocket 연결을 해제합니다...');
            socket.disconnect();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [enabled, equipmentCode, onConnected]);

    return {
        equipmentCounter: messages[messages.length - 1]?.equipmentCounter,
        equipmentLimitCounter: messages[messages.length - 1]?.equipmentLimitCounter,
        isLimitHit: messages[messages.length - 1]?.isLimitHit,
    };
};
