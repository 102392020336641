import { Flex } from '@mantine/core'; // 필요한 컴포넌트를 임포트하세요.
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from '@sizlcorp/sizl-api-document/dist/models';
import { BaseText } from 'components/common/Text/baseText';
import { BaseTitle } from 'components/common/title/BaseTitle';
import { useTranslation } from 'react-i18next';
import { WorkTable } from '../../../common/table/workTable';


const WorkLocations = Object.assign({}, {
    Text: BaseText,
    Title: BaseTitle,
    Table: WorkTable
});

interface WorkLocationsComponentProps {
    workData: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined
}

export const WorkLocationsComponent = (params: WorkLocationsComponentProps) => {

    const { workData } = params
    const { t } = useTranslation();

    return (
        <>
            <Flex w={"100%"} direction={'column'} p={10} gap={10}>
                <Flex justify={"space-between"}>
                    <WorkLocations.Title>{t("원부자재 로케이션")}</WorkLocations.Title>
                    <Flex wrap={"wrap"}>
                        <WorkLocations.Text>
                            [{workData?.currentRoutingOutsourceData?.fromLocationCode ?? workData?.locationSetting?.fromLocation?.code}]
                        </WorkLocations.Text>
                        <WorkLocations.Text>
                            {workData?.currentRoutingOutsourceData?.fromLocationName ?? workData?.locationSetting?.fromLocation?.name}
                        </WorkLocations.Text>
                    </Flex>
                </Flex>
                <Flex justify={"space-between"}>
                    <WorkLocations.Title>{t("재공품 입고 로케이션")}</WorkLocations.Title>
                    <Flex wrap={"wrap"}>
                        <WorkLocations.Text>
                            [{workData?.currentRoutingOutsourceData?.toLocationCode ?? workData?.locationSetting?.toLocation?.code}]
                        </WorkLocations.Text>
                        <WorkLocations.Text>
                            {workData?.currentRoutingOutsourceData?.toLocationName ?? workData?.locationSetting?.toLocation?.name}
                        </WorkLocations.Text>
                    </Flex>
                </Flex>
                <Flex justify={"space-between"}>
                    <WorkLocations.Title>{t("생산 로케이션")}</WorkLocations.Title>
                    <Flex wrap={"wrap"}>
                        <WorkLocations.Text>
                            [{workData?.locationSetting?.targetLocation?.code ?? '-'}]
                        </WorkLocations.Text>
                        <WorkLocations.Text>
                            {workData?.locationSetting?.targetLocation?.name ?? '-'}
                        </WorkLocations.Text>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default WorkLocationsComponent;