import { Button } from "@mantine/core";
import { ChangeNumberButtonProps } from "./types";

export const ChangeNumberButton = (props: ChangeNumberButtonProps) => {
  const { num, onChange, btnType = 'increase', ...etcParams } = props;

  const onClick = () => {
    btnType === 'increase'
      ? onChange && onChange(Number(etcParams?.value) + num)
      : onChange && onChange(Number(etcParams?.value) - num);
  };

  return (
    <Button onClick={onClick} {...etcParams}>
      {btnType === 'increase' ? '+' : '-'}{num}
    </Button>
  );
};

export default ChangeNumberButton;


