import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { defects } from "api/defects/useDefectsQuery";
import { inventories } from "api/inventories/useInventoriesQuery";
import { workLogs } from "api/logs/useWorksLogsQuery";
import { lots } from "api/lots/useLotsQuery";
import { routingOutsources } from "api/routingOutsources/useRoutingOutsourcesQuery";
import { works } from "api/work/useWorkQuery";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useState } from "react";
import { useQuery } from "react-query";


interface PageState {
  [key: string]: { page: number; pageSize: number };
}

export const useGetWorkData = () => {
  const { workId, setRoutingCode } = useLocalStorage();

  // 페이지 상태 객체
  const [pageState, setPageState] = useState<PageState>({});

  const setPage = (apiName: string, page: number, pageSize: number) => {
    setPageState((prevState) => ({
      ...prevState,
      [apiName]: { page, pageSize },
    }));
  };

  const { data: defectsData, isLoading: defectsLoading, error: defectsError } = useQuery(defects.find({}));

  const { data: workData, isLoading: worksLoading, error: worksError } = useQuery({
    ...works.detail({ workId }, {
      params: {
        defectCodes: defectsData?.data?.map((row) => row.code),
        populate: ["summary", "item", "equipment", "routingData", "productionPlan", "locationSetting", "currentLotSummary", "currentRoutingOutsourceData"],
      },
    }), enabled: !!workId && !!defectsData,
    onSuccess : (data) => {
      setRoutingCode(data.data?.routingData?.code);
    },
    cacheTime: 0,
  });

  const { data: workLotsData, isLoading: lotsLoading, error: lotsError } = useQuery({
    ...lots.detail({ lotId: workData?.data?.productionPlan?.lastLotId as number }),
    enabled: !!workData?.data?.productionPlan?.lastLotId,
  });

  const { data: workInventoriesData, isLoading: inventoriesLoading, error: inventoriesError } = useQuery({
    ...inventories.get({
      query: {
        $and: [
          {
            itemCode: workData?.data?.routingData?.routingBoms?.map((bom) => bom.itemCode),
          },
          { locationCode: workData?.data?.equipment?.toLocationCode ?? workData?.data?.currentRoutingOutsourceData?.toLocationCode ?? workData?.data?.locationSetting?.toLocation?.code ?? workData?.data?.routingData?.operation?.toLocationCode },
          { quantity: { $ne: 0 } },
        ],
      },
      populate: ["item", "lot"],
    }), enabled: !!workData,
    cacheTime: 0,
  });

  const { data: workInputLogsAll, isLoading: inputLogsAllLoading, error: inputLogsAllError } = useQuery({
    ...workLogs.find({
      query: {
        $and: [
          { workId: workId },
          {
            $or: [
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT } },
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT } },
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE } },
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END } },
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE } },
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME } },
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START } },
            ],
          },
        ],
      },
      populate: ["downtimeReasonName", "defectName", "itemUnit", "alreadyCanceled", "alreadyCanceledWorkLog"],
      sort: "-createdAt",
    }), enabled: !!workId,
  });

  const { data: workInputLogs, isLoading: inputLogsLoading, error: inputLogsError } = useQuery({
    ...workLogs.get({
      query: {
        $and: [
          { workId: workId },
          {
            $or: [
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT } },
            ],
          },
        ],
      },
      page: pageState?.workInputLogs?.page,
      populate: ["downtimeReasonName", "defectName", "itemUnit", "alreadyCanceled", "alreadyCanceledWorkLog"],
      sort: "-createdAt",
    }), enabled: !!workId,
    cacheTime: 0,
  });

  const { data: workOutsourceData, isLoading: outsourceLoading, error: outsourceError } = useQuery({
    ...routingOutsources.detail({ routingOutsourceId: workData?.data?.routingOutsourceId as number }),
    enabled: !!workData?.data?.routingOutsourceId,
  });

  const isLoading = defectsLoading || worksLoading || lotsLoading || inventoriesLoading || inputLogsLoading || outsourceLoading;
  const error = defectsError || worksError || lotsError || inventoriesError || inputLogsError || outsourceError;

  return {
    workData: workData?.data,
    workLotsData: workLotsData?.data,
    workInventoriesData: workInventoriesData?.data,
    workInputLogs: workInputLogs?.data,
    workInputLogsAll: workInputLogsAll?.data,
    workOutsourceData: workOutsourceData?.data,
    isLoading,
    error,
    // 페이지 변경 함수 반환
    setPage,
    pageState,
  };
};
