import { Flex } from '@mantine/core'; // 필요한 컴포넌트를 임포트하세요.
import { WorkLogsGet200ResponseRowsInner } from '@sizlcorp/sizl-api-document/dist/models';
import { BaseText } from 'components/common/Text/baseText';
import { BaseButton } from 'components/common/button/baseButton';
import { BaseTitle } from 'components/common/title/BaseTitle';
import dayjs from 'dayjs';
import { WorkTable } from '../../../common/table/workTable';


const WorkInputLogs = Object.assign({}, {
    Text: BaseTitle,
    Title: BaseText,
    Table: WorkTable,
    Button: BaseButton
});

interface WorkInputLogsComponentProps {
    workInputLogsData: WorkLogsGet200ResponseRowsInner[] | undefined
    onCancelInput: (id: number) => void
}

export const WorkInputLogsComponent = (params: WorkInputLogsComponentProps) => {

    const { workInputLogsData, onCancelInput } = params

    return (
        <>
            <WorkInputLogs.Table
                columns={[
                    "Grade",
                    "로트명",
                    <><div>로트</div><div>유효기한</div></>,
                    <><div>출고</div><div>로케이션</div></>,
                    "수량",
                    <><div>입고</div><div>로케이션</div></>,
                    "작업자",
                    "일시",
                    "액션"
                ]}
                flex={[2, 2, 2, 2, 1, 2, 1, 2, 3]}
                data={
                    workInputLogsData?.flatMap((row: WorkLogsGet200ResponseRowsInner) => {
                        const inputRow = [
                            row.logData?.incoming?.itemCode || "-",
                            row.logData?.incoming?.lotData?.name || "-",
                            row.logData?.incoming?.lotData?.expiration
                                ? dayjs(row.logData?.incoming?.lotData?.expiration).format("YYYY-MM-DD")
                                : "-",
                            row.logData?.outgoing?.locationData?.name,
                            <div style={{ textAlign: "right" }}>{row.logData?.incoming?.quantity + " " + (row.unitText ?? '')}</div>,
                            row.logData?.incoming?.locationData?.name,
                            row.logData?.incoming?.userCode,
                            dayjs(row.logData?.incoming?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                            <Flex w={'100%'} align={'center'} justify={'center'}>
                                {
                                    row.alreadyCanceled ?
                                        <WorkInputLogs.Text c={'red'}>
                                            {'취소됨'}
                                        </WorkInputLogs.Text>
                                        : <WorkInputLogs.Button
                                            size='md'
                                            onClick={() => onCancelInput(row?.id as number)}
                                            color={'gray.6'}
                                        >{'취소하기'}</WorkInputLogs.Button>
                                }
                            </Flex>
                        ];
                        return [inputRow];
                    }) as string[][]
                }
            />
        </>
    );
};

export default WorkInputLogsComponent;