import { Flex } from "@mantine/core";

interface HeaderProps {
  children: React.ReactNode;
}

export const WorkHeader = ({ children }: HeaderProps) => {
  return (
    <Flex
      w={'100%'}
      h={'100%'}
      justify={'space-between'}
      align={'center'} >
      {children}
    </Flex>
  );
};

