export const TransferWorkStatus = (status: "TODO" | "DOING" | "DONE") => {
  switch (status) {
    case "TODO":
      return "시작예정";
    case "DOING":
      return "진행중";
    case "DONE":
      return "완료";
    default:
      return "";
  }
};

type WorkTrackingStatusKey =
  | "WAITING"
  | "PAUSED"
  | "WORKING"
  | "DONE"
  | undefined;

const workTrackingStatus = {
  WAITING: "작업대기",
  PAUSED: "일시정지",
  WORKING: "작업중",
  DONE: "작업완료",
};

export const getWorkTrackingStatus = (status: WorkTrackingStatusKey) => {
  if (!status) return { status: "-", color: "gray.7" };

  let color: string;
  switch (status) {
    case "WAITING":
      color = "gray.7";
      break;
    case "PAUSED":
      color = "orange.7";
      break;
    case "WORKING":
      color = "green.7";
      break;
    case "DONE":
      color = "red.7";
      break;
    default:
      color = "";
      break;
  }

  return {
    status: workTrackingStatus[status],
    color: color
  }
};
