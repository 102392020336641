import { useQuery } from "react-query";

import { DefectsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import DefaultInstance from "instance/axios";

export const WORK_DEFECT_GET_KEY = "defects-get-autocomplete-keyword";

interface workDefectReasonsProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export const useWorkDefectAutoComplete = (
  focus = false,
  keyword = "",
  defectOperationCode?: string,
  options?: {
    onSuccess: (defect: workDefectReasonsProps[]) => void;
  }
) => {
  return useQuery(
    [WORK_DEFECT_GET_KEY, keyword],
    () =>
      DefaultInstance.defectsGet({
        pageSize: 30,
        search: keyword,
        searchFields: ["name", "code"],
        query: JSON.stringify({
          $or: [
            { operationCode: defectOperationCode },
            { operationCode: null }
          ]
        })
      }).then((res) => res.data),
    {
      enabled: focus,
      select: (data: DefectsGet200Response): workDefectReasonsProps[] =>
        data.rows?.map((equipment) => {
          return {
            value: equipment.code as string,
            label: equipment.name as string,
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

export const useWorkDefectsCode = (
  enabled: boolean = false,
  code: string | null,
  defectOperationCode?: string,
  options?: {
    onSuccess: (data?: workDefectReasonsProps[]) => void;
  },
) => {
  return useQuery(
    ["equipments-get-code", code],
    () =>
      DefaultInstance.defectsGet({
        ...(code && { search: code }),
        searchFields: ["code"],
        query: JSON.stringify({
          $or: [
            { operationCode: defectOperationCode },
            { operationCode: null }
          ]
        })
      }).then((res) => res.data),
    {
      enabled,
      select: (data: DefectsGet200Response) =>
        data?.rows?.map((defect) => {
          return {
            value: defect.code as string,
            label: defect.name as string,
          };
        }),
      onSuccess: options?.onSuccess,
    }
  );
};
