import { WorkByRowPage as DaeilWorkByRowPage } from "pages/companies/daeil/workByRow";
import { WorkByRowPage as DaouWorkByRowPage } from "pages/companies/daou/workByRow";
import { WorkByRowPage as SpeedrackWorkByRowPage } from "pages/companies/speedrack/workByRow";
import { WorkByRowPage as CommonWorkByRowPage } from "./workByRow";
export const WorkByRowPageByCompanies = () => {
    // 환경 변수 값에 따라 업체 구분
    const companyName = process.env.REACT_APP_COMPANY_KEY;
    let pageComponent;

    // 업체별로 다른 페이지 렌더링
    if (companyName === 'speedrack') {
        pageComponent = <SpeedrackWorkByRowPage />;
    } else if (companyName === 'daou') {
        pageComponent = <DaouWorkByRowPage />;
    } else if (companyName === 'dail') {
        pageComponent = <DaeilWorkByRowPage />;
    } else {
        pageComponent = <CommonWorkByRowPage />;
    }

    return (
        <>
            {pageComponent}
        </>
    );
};

export default WorkByRowPageByCompanies;
