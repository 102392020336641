import { Text } from '@mantine/core'; // 필요한 컴포넌트를 임포트하세요.
import { WorkLogsGet200ResponseRowsInner } from '@sizlcorp/sizl-api-document/dist/models';
import { BaseText } from 'components/common/Text/baseText';
import { BaseTitle } from 'components/common/title/BaseTitle';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { WorkTable } from '../../../common/table/workTable';


const WorkTimeTrack = Object.assign({}, {
    Text: BaseText,
    Title: BaseTitle,
    WorkText: Text,
    Table: WorkTable
});

type ExtendedWorkLogs = WorkLogsGet200ResponseRowsInner & { endTime?: string };

interface WorkTimeTrackComponentProps {
    workTrackLogsData: WorkLogsGet200ResponseRowsInner[] | undefined
}

export const WorkTimeTrackComponent = (params: WorkTimeTrackComponentProps) => {

    const { workTrackLogsData } = params
    const { t } = useTranslation();
    return (
        <>
            <WorkTimeTrack.Table
                columns={[t("타입"), t("시작시간"), t("종료시간"), t("비가동 사유")]}
                flex={[1, 2, 2, 2]}
                data={workTrackLogsData?.reduce((acc, row, index, self) => {
                    // 일시정지인 경우 작업재개가 있는경우 작업재개를 찾아서 endTime에 종료시간을 추가
                    const extendedRow = row as ExtendedWorkLogs;
                    if (extendedRow.workLogType === "TIME_TRACKING_PAUSE") {
                        const nextResumeIndex = self.slice(index + 1).findIndex((r) => r.workLogType === "TIME_TRACKING_RESUME");
                        if (nextResumeIndex !== -1) {
                            extendedRow.endTime = self[index + nextResumeIndex + 1].createdAt;
                        }
                    } else if (extendedRow.workLogType === "TIME_TRACKING_END") {
                        extendedRow.endTime = extendedRow.createdAt;
                    }
                    // 작업로그 중 작업 재개가 아닌경우 배열(acc)에 추가
                    if (extendedRow.workLogType !== "TIME_TRACKING_RESUME") {
                        acc.push(extendedRow);
                    }
                    return acc;
                }, [] as ExtendedWorkLogs[]).map((row) => {
                    const workLogTypeText = row.workLogType === "TIME_TRACKING_START" ? "작업 시작" :
                        row.workLogType === "TIME_TRACKING_END" ? "작업 종료" :
                            row.workLogType === "TIME_TRACKING_PAUSE" ? "일시 정지" : "";
                    const startTime = row.workLogType !== "TIME_TRACKING_END" ? dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss") : "-";
                    const endTime = row.endTime ? dayjs(row.endTime).format("YYYY-MM-DD HH:mm:ss") : "-";
                    const downtimeReasonName = row.workLogType === "TIME_TRACKING_PAUSE" ? (row.downtimeReasonName as string) : "-";

                    return [
                        t(workLogTypeText),
                        startTime,
                        endTime,
                        downtimeReasonName,
                    ];
                })}
            />
        </>
    );
};

export default WorkTimeTrackComponent;