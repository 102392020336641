import { Flex } from "@mantine/core";

interface WorkContentProps {
    children: React.ReactNode;
}

export const MainContent = ({ children }: WorkContentProps) => {
    return <Flex
        className="main-content"
        w={'100%'}
        p={'0.625rem'}
        gap={'0.625rem'}
        align={'flex-start'}>
        {children}
    </Flex>;
};


