import { Flex } from "@mantine/core";

interface WorkContentProps {
    children: React.ReactNode;
}

export const LeftContent = ({ children }: WorkContentProps) => {
    return <Flex
        className="left-content"
        w={'50%'}
        gap={'1.25rem'}
        align={'flex-start'}
        direction={'column'}
    >
        {children}
    </Flex>;
};


