import { Progress, ProgressProps } from "@mantine/core";
import React from "react";
import { theme } from "styles/theme";

interface StyledProgressProps
  extends ProgressProps,
  React.RefAttributes<HTMLDivElement> { }

const WorkProgress = (params: StyledProgressProps) => {
  const { value, sections, ...etcParams } = params;

  const progressColor = () => {
    if (value && value >= 71) return "#009000";
    return `${theme.colors?.blue?.[7]}`;
  };

  const roundToDecimalPrecision = ({
    sections,
  }: Pick<ProgressProps, "sections">) => {
    return sections?.map((section) => ({
      ...section,
      label: `${Number(section.value.toFixed(2))}%`,
    }));
  };

  return (
    <Progress
      animate
      color={progressColor()}
      sections={
        roundToDecimalPrecision({ sections: sections }) as Pick<
          ProgressProps,
          "sections"
        >["sections"]
      }
      styles={{
        root: {
          width: '47.8125rem',
          height: '2rem'
        },
        bar: {
          maxWidth: "100%",
        },
      }}
      value={value}
      bg={'gray.5'}
      {...etcParams}
    />
  );
};

export default WorkProgress;
