import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { getBeforeWorkingDayAndToday } from 'utils/DateUtil';

export interface WorkSearchContextProps {
  activePage: number;
  dates: [Date, Date];
  value: string[];
  item: string;
  spec: string;
}

export const initialSearchParams: WorkSearchContextProps = {
  activePage: 1,
  dates: getBeforeWorkingDayAndToday(),
  value: [],
  item: '',
  spec: ''
};

export const WorksSearchStateContext = createContext<WorkSearchContextProps | undefined>(undefined);

export const WorksSearchActionContext = createContext<{
  setActivePage: (activePage: number) => void;
  setDates: (dates: [Date, Date]) => void;
  setValue: (value: string[]) => void;
  setItem: (item: string) => void;
  setSpec: (spec: string) => void;
} | undefined>(undefined);

export const WorksSearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchParams, setSearchParams] = useState<WorkSearchContextProps>(() => {
    const savedParams = localStorage.getItem('worksSearchParams');
    return savedParams ? JSON.parse(savedParams) : initialSearchParams;
  });

  useEffect(() => {
    localStorage.setItem('worksSearchParams', JSON.stringify(searchParams));
  }, [searchParams]);

  const actions = useMemo(() => ({
    setActivePage: (activePage: number) => setSearchParams((prev) => ({ ...prev, activePage })),
    setDates: (dates: [Date, Date]) => setSearchParams((prev) => ({ ...prev, dates })),
    setValue: (value: string[]) => setSearchParams((prev) => ({ ...prev, value })),
    setItem: (item: string) => setSearchParams((prev) => ({ ...prev, item })),
    setSpec: (spec: string) => setSearchParams((prev) => ({ ...prev, spec })),
  }), []);

  return (
    <WorksSearchActionContext.Provider value={actions}>
      <WorksSearchStateContext.Provider value={searchParams}>
        {children}
      </WorksSearchStateContext.Provider>
    </WorksSearchActionContext.Provider>
  );
};