interface WorkThProps {
  children: React.ReactNode;
  height?: number;
  width?: number;
  align?: "left" | "center" | "right";
  flex?: number;
}

export const TableData = (params: WorkThProps) => {
  const { children, height, width, align, flex } = params;
  const calculatedWidth = `${String(children).length * 6}px`; // 길이에 따른 width 계산

  return (
    <td
      style={{
        color: 'white.0',
        fontFamily: "Noto Sans",
        fontSize: '1.25rem', /* 20px */
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '1.9375rem', /* 31px */
        flex: `${flex}`,
      }}
      {...params}
    >
      <div
        style={{
          width: "100%",
          textAlign:
            typeof children === "number" || !isNaN(Number(children))
              ? "right"
              : "left",
        }}
      >
        {typeof children === "number" ||
          (typeof children === "number" || !isNaN(Number(children))
            ? Number(children).toLocaleString()
            : children)}
        {typeof children === "number" || (!isNaN(Number(children)))}
      </div>
    </td>
  );
};
