import { Flex, FlexProps } from "@mantine/core";

interface HeaderProps extends FlexProps {
    children: React.ReactNode;
}

export const WorkSection = ({ children, ...params }: HeaderProps) => {
    return (
        <Flex
            w={'100%'}
            direction={'column'}
            align={'flex-start'}
            gap={'0.625rem'}
            {...params}
        >
            {children}
        </Flex>
    );
};

