import { Input, InputProps } from "@mantine/core";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";


interface InventoryBarcodeInputProps extends InputProps {
    // value: string
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    value: string
}

export const InventoryBarcodeInput = ({ onKeyDown, handleBlur, value, ...etcParams }: InventoryBarcodeInputProps) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <Input.Wrapper
            size="1.4rem"
            label={t("바코드 정보 입력")}
            auto-focus
        >
            <Input
                value={value}
                onKeyDown={onKeyDown}
                aria-label="바코드정보"
                size="lg"
                data-autofocus
                ref={inputRef}
                placeholder="Enter Input And Take a Barcode "
                {...etcParams}
                onBlur={(e) => {
                    etcParams.onBlur && etcParams.onBlur(e)
                    handleBlur && handleBlur(e)
                }}
            />
        </Input.Wrapper>
    );
}