interface WorkThProps {
  children: React.ReactNode;
  height?: number;
  width?: number;
  align?: "left" | "center" | "right";
  flex?: number;
}

export const TableHeader = (params: WorkThProps) => {
  const { children, height, width, align, flex } = params;
  return (
    <th
      style={{
        fontSize: "1.25rem",
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: '1.9375rem',
        color: "gray.6",
        flex: `${flex}`,
      }}
      {...params}
    >
      {children}
    </th>
  );
};
