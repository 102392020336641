import { notifications } from "@mantine/notifications";
import { ProductionActionApiProductionPlansProductionPlanIdSplitProductLotPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { WORK_KEY } from "api/work/useWorkQuery";
import { ProductionInstance } from "instance/axios";
import { useMutation, useQueryClient } from "react-query";

const fetchProductionPlansProductionPlanIdSplitProductLotPost = ({
    productionPlanId,
    productionPlansProductionPlanIdSplitProductLotPostRequest,
}: ProductionActionApiProductionPlansProductionPlanIdSplitProductLotPostRequest) =>
    ProductionInstance.productionPlansProductionPlanIdSplitProductLotPost({
        productionPlanId,
        productionPlansProductionPlanIdSplitProductLotPostRequest,
    })

export const useProductionPlansProductionPlanIdSplitProductLotPost = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({
            productionPlanId,
            productionPlansProductionPlanIdSplitProductLotPostRequest,
        }: ProductionActionApiProductionPlansProductionPlanIdSplitProductLotPostRequest) =>
            fetchProductionPlansProductionPlanIdSplitProductLotPost({ productionPlanId, productionPlansProductionPlanIdSplitProductLotPostRequest }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(WORK_KEY);
                notifications.show({
                    title: "파레트 분리",
                    message: "파레트가 성공적으로 분리 되었습니다.",
                });

            },
        }
    );
};
export default useProductionPlansProductionPlanIdSplitProductLotPost;
