import Index from "router/Routes";
import "./App.css";

function App() {
  return (
    <Index />
  );
}

export default App;
