import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, ReactNode, forwardRef, useState } from "react";

import styled from "@emotion/styled";
import {
  EquipmentOption,
  useEquipmentsAutoComplete,
  useEquipmentsCode,
} from "components/common/autoComplete/equipment/auto-useGetEquipmentsQuery";

import { IconX } from "@tabler/icons-react";
import { useModal } from "context/ModalStackManager";

interface EquipmentProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface EquipmentAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (equipmentCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

// 입력값: value (equipment 모델의 code)
// 출력값: onChange (equipment 모델의 code)

export const EquipmentAutoComplete = (params: EquipmentAutoCompleteProps) => {
  const {
    value: equipmentCode,
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useEquipmentsAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useEquipmentsCode(
    !!equipmentCode,
    equipmentCode ?? null
  );

  let selectedEquipment = initialOptions?.find(
    (equipment) => equipment.value === equipmentCode
  );
  const onChangeHandler = (e: string | null) => {
    selectedEquipment = options?.find((equipment) => equipment.value === e);
    onChange && onChange(e);
  };

  const SelectEquipment = forwardRef<HTMLDivElement, EquipmentProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(설비코드: {code})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <Select
      styles={
        equipmentCode
          ? {
            input: {
              width: width,
              fontSize: "0.8em",
              paddingBottom: "0.5rem",
            },
          }
          : { input: { width: width } }
      }
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedEquipment?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"Code: " + selectedEquipment?.value}
            </div>
          )}
        </div>
      )}
      value={equipmentCode}
      itemComponent={SelectEquipment}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: EquipmentProps[], option: EquipmentProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={EquipmentInfo({
        equipment: selectedEquipment as EquipmentOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewEquipment({
        equipmentName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const EquipmentInfo = (params: {
  equipment?: EquipmentOption;
  onChange: (equipmentCode: string | null) => void;
}) => {
  const { equipment, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return equipment?.value ? (
    <EquipmentInfoLabel>
      {/* ({equipment.value})  */}
      <IconX size="2rem" onClick={clearHandler} />{" "}
    </EquipmentInfoLabel>
  ) : null;
};

const AddNewEquipment = (params: {
  equipmentName: string;
  onChange: (equipmentCode: string) => void;
}) => {
  const { equipmentName, onChange } = params;
  const { openModal, closeModal } = useModal();

  const onCloseHandler: EventHandler<any> = (values) => {
    closeModal(values);
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    // openModal(
    //   <EquipmentForm name={equipmentName} onSubmit={onCloseHandler} />,
    //   null,
    //   "신규 설비 등록",
    //   true
    // ).then((result) => {
    //   onChange(result.code);
    // });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      {/* 신규 설비({equipmentName}) 등록 */}
    </Button>
  );
};
const EquipmentInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  position: absolute;
`;
