import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export function SiteForm(params: {
  name: string;
  onSubmit?: (values: any) => void;
}) {
  const { name, onSubmit } = params;
  const form = useForm({
    initialValues: {
      terms: false,
      name: name,
    },
  });

  return (
    <Box maw={320} mx="auto">
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <TextInput
          label="name"
          placeholder="name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="code"
          placeholder="code"
          {...form.getInputProps("code")}
        />
        {/* <TextInput
          label="itemType"
          placeholder="itemType"
          {...form.getInputProps("itemType")}
        /> */}
        {/* <TextInput
          label="Last name"
          placeholder="Last name"
          mt="md"
          {...form.getInputProps("user.lastName")}
        /> */}
        {/* <Checkbox
          label="I accepts terms & conditions"
          mt="sm"
          {...form.getInputProps("terms", { type: "checkbox" })}
        /> */}
        {/* <Text size="sm" weight={500} mt="xl">
          Form values:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code> */}
        <Button style={{ marginTop: "30px" }} type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
}
