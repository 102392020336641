import { useSocketIo } from "hooks/useSocketIo";
import { UseGetEquipmentCounterBySettingProps } from "./types";

export const useGetEquipmentCounterBySetting = (
  params: UseGetEquipmentCounterBySettingProps
) => {
  const { workData } = params;

  const isGetCounter = canFetchCounter({
    id: workData?.id,
    activeWorkId: workData?.equipment?.activeWorkId,
  });

  const enabledGetCounter =
    workData
    && (workData?.trackingStatus === "WORKING" || workData?.trackingStatus === "PAUSED")
    && isGetCounter
    && workData?.externalCounterSetting !== "HANDWRITING";

  const { equipmentCounter, equipmentLimitCounter, isLimitHit } = useSocketIo({
    equipmentCode: workData?.equipment?.code as string,
    enabled: enabledGetCounter,
    equipmentId: workData?.equipment?.id as number,
  })

  return {
    equipmentCounter,
    equipmentLimitCounter,
    isLimitHit,
  };
};

const canFetchCounter = ({ id, activeWorkId }: any) => {
  return id === activeWorkId;
};
