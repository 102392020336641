
import { createMutationKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { ProductionActionApiWorksWorkIdPerformancePutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { ProductionInstance } from "instance/axios";

export const mutatePerformance = createMutationKeys('performance', {




    update: (params: ProductionActionApiWorksWorkIdPerformancePutRequest) => ({
        mutationKey: [params],
        mutationFn: () => ProductionInstance.worksWorkIdPerformancePut(params)
    }),
});

export const defectQueryKeys = mergeQueryKeys(mutatePerformance);
