import { SitesGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { MasterInstance } from "../../instance/axios";

// 추후에 쿼리KEY를 관리할 예정이다.

export const DEFECT_QUERY_KEY = "/sites";

const getSiteFetch = (params?: any) => {
  if (params) {
    const queryString = JSON.stringify(params.query);
    return MasterInstance.sitesGet({
      query: [queryString],
      search: params.search,
      searchFields: params.searchFields,
      page: params.activePage ?? 1,
      pageSize: 10,
    });
  } else return MasterInstance.sitesGet({});
};

const useSitesGetQuery = (
  params?: any,
  options?: {
    onSuccess: (data: SitesGet200Response) => void;
  }
) => {
  return useQuery<AxiosResponse<SitesGet200Response, any[]>>({
    queryKey: params
      ? [
        DEFECT_QUERY_KEY,
        params.activePage,
        JSON.stringify(params.query),
        params.searchFields,
        params.search,
      ]
      : [DEFECT_QUERY_KEY],
    queryFn: () => (params ? getSiteFetch(params) : getSiteFetch()),
  });
};

export default useSitesGetQuery;
