import { Button, ButtonProps } from "@mantine/core";
import { useMediaQuery } from '@mantine/hooks';

export const BaseButton = (params: ButtonProps & React.HTMLAttributes<HTMLButtonElement>) => {
    const { children } = params;

    const isPortrait = useMediaQuery('(max-width: 1200px) and (orientation: portrait)');

    return (
        <Button
            radius="sm"
            size={isPortrait ? 'md' : 'xl'}
            {...params}
        >
            {children}
        </Button>
    );
};
