import { ProductionActionApiWorksWorkIdDefectPutRequest, ProductionActionApiWorksWorkIdPerformancePutRequest, ProductionPlansGet200ResponseRowsInnerWorksInner, WorksWorkIdDefectPut200Response, WorksWorkIdDefectPutRequest, WorksWorkIdDeleteRequestExternalCounterSettingEnum, WorksWorkIdPerformancePut200Response, WorksWorkIdPerformancePutRequest } from '@sizlcorp/sizl-api-document/dist/models';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { MutateOptions } from 'react-query';
import { usePutWorkData } from './usePutWorkData';


interface WorkDataProps {
    workData: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined
}

const useWorkActions = ({ workData }: WorkDataProps) => {

    const { performanceMutate, defectMutate, settingMutate,
        flushMutate, pauseMutate, endMutate, startMutate, resumeMutate, correctMutate, cancelMutate, defectMutateAsync, performanceMutateAsync, isMutateLoading, cancelLoading } = usePutWorkData();

    const workId = workData?.id as number;
    const equipmentCode = workData?.equipmentCode as string;
    const locationCode = workData?.locationSetting?.toLocation?.code as string;

    const onPerformance = useCallback((performanceRequest: WorksWorkIdPerformancePutRequest,
        options: MutateOptions<AxiosResponse<WorksWorkIdPerformancePut200Response, any>, unknown, ProductionActionApiWorksWorkIdPerformancePutRequest, unknown>) => {

        return performanceMutate({
            worksWorkIdPerformancePutRequest: performanceRequest,
            workId: workId,
        }, options);

    }, [workData]);

    const onFlush = useCallback(() => {
        return flushMutate({
            wmsFlushPutRequest: {
                locationCode: locationCode,
            },
        })
    }, [workData]);

    const onStart = useCallback(() => {
        return startMutate({
            worksWorkIdTrackingStartPostRequest: {
                workId: workId,
            },
            workId: workId,
        });
    }, [workData]);

    const onPause = useCallback((downtimeReasonCode: string) => {
        pauseMutate({
            worksWorkIdTrackingPausePostRequest: {
                workId: workId || 0,
                downtimeReasonCode: downtimeReasonCode,
            },
            workId: workId || 0,
        });
    }, [workData]);

    const onEnd = useCallback(() => {

        endMutate({
            workId: workId,
            worksWorkIdTrackingStartPostRequest: {
                workId: workId,
            },
        });
    }, [workData]);

    const onResume = useCallback(() => {
        resumeMutate({
            workId: workId,
            worksWorkIdTrackingStartPostRequest: {
                workId: workId,
            },
        });
    }, [workData]);

    const onCorrect = useCallback(() => {
        correctMutate({
            equipmentsCorrectionPutRequest: {
                workId: workId,
            },
        });
    }, [workData]);

    const onCancel = useCallback((id: number) => {
        cancelMutate({
            workLogId: id,
        });
    }, [workData]);

    const onSetting = useCallback((settingType: WorksWorkIdDeleteRequestExternalCounterSettingEnum) => {
        settingMutate({
            workId: workId as number,
            worksWorkIdDeleteRequest: {
                externalCounterSetting: settingType,
            },
        });
    }, [workData])

    const onDefect = useCallback((defectRequest: WorksWorkIdDefectPutRequest,
        options?: MutateOptions<AxiosResponse<WorksWorkIdDefectPut200Response, any>, unknown, ProductionActionApiWorksWorkIdDefectPutRequest, unknown>) => {
        defectMutate({
            workId: workId as number,
            worksWorkIdDefectPutRequest: defectRequest
        }, options)
    }, [workData])

    return { onPerformance, onFlush, onStart, onPause, onEnd, onResume, onCorrect, onCancel, onSetting, onDefect, defectMutateAsync, performanceMutateAsync, isMutateLoading, cancelLoading };
};

export default useWorkActions;