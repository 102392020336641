import {
  DefaultApiOperationsOperationIdDeleteRequest,
  DefaultApiOperationsOperationIdPutRequest,
  DefaultApiOperationsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import DefaultInstance from "instance/axios";
import { useMutation, useQueryClient } from "react-query";
const GET_ROUTINGS_QUERY_KEY = "operations-get";
interface CreateParams extends DefaultApiOperationsPostRequest { }
interface UpdateParams extends DefaultApiOperationsOperationIdPutRequest { }
interface DeleteParams extends DefaultApiOperationsOperationIdDeleteRequest { }

type MutationType = "create" | "update" | "delete";

type Params<T extends MutationType> = T extends "create"
  ? CreateParams
  : T extends "update"
  ? UpdateParams
  : DeleteParams;

const createFetcher = (params: CreateParams) =>
  DefaultInstance.operationsPost({
    operationsGetRequest: params.operationsGetRequest,
  });
const updateFetcher = (params: UpdateParams) =>
  DefaultInstance.operationsOperationIdPut(params);
const deleteFetcher = (params: DeleteParams) =>
  DefaultInstance.operationsOperationIdDelete(params);

const useOperationsPostQuery = <T extends MutationType>(type: T) => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (params: Params<typeof type>) => createFetcher(params as CreateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ROUTINGS_QUERY_KEY);
      },
    }
  );

  const updateMutation = useMutation(
    (params: Params<typeof type>) => updateFetcher(params as UpdateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ROUTINGS_QUERY_KEY);
      },
    }
  );

  const deleteMutation = useMutation(
    (params: Params<typeof type>) => deleteFetcher(params as DeleteParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ROUTINGS_QUERY_KEY);
      },
    }
  );

  if (type === "create") {
    return createMutation;
  } else if (type === "update") {
    return updateMutation;
  } else if (type === "delete") {
    return deleteMutation;
  }
  throw new Error("Invalid mutation type");
};

export default useOperationsPostQuery;
