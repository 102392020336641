import { ActionIcon, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconDeviceFloppy, IconSettings } from "@tabler/icons-react";
import { BaseButton } from "components/common/button/baseButton";
import ChangeNumberButton from "components/common/button/changeNumberButton";
import { EndSetForm } from "components/common/form/work/EndSetForm";
import { BoxNumberInput } from "components/common/input/boxNumberInput";
import { BaseTitle } from "components/common/title/BaseTitle";
import { EndReportBoxProps } from "components/pages/types";
import { useModal } from "context/ModalStackManager";
import { WorkSection } from "layouts/pages/work/section";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ReportBox = Object.assign({}, {
  Title: BaseTitle,
  Button: BaseButton,
  Section: WorkSection,
  Input: BoxNumberInput,
  ChangeNumberButton: ChangeNumberButton,
});

export const WorkEndReportBox = (params: EndReportBoxProps) => {
  const { openModal } = useModal();
  const {
    value,
    disabled,
    onSubmit,
    externalCounterSetting,
    onChangeSetting,
    activeType,
  } = params;

  const form = useForm({
    initialValues: {
      end: value
    },
  });

  useEffect(() => {
    form.setFieldValue("end", value);
  }, [value]);

  useEffect(() => {
    if (
      externalCounterSetting === "COUNTER_AND_TEM_SAVE" &&
      (activeType === "WORKING" || activeType === "PAUSED")
    ) {
      onSubmit && onSubmit(form.values.end + "");
    }

  }, [externalCounterSetting, value, activeType]);

  const { t } = useTranslation();

  return (
    <ReportBox.Section>
      <ReportBox.Section>
        <Flex w={'100%'} justify={'space-between'}>
          <Flex gap={10} align={'center'}>
            <ReportBox.Title fz={"1.625rem"} c={"#FFFFFF"}>{t('생산실적 입력')}</ReportBox.Title>
            <ActionIcon size={"md"}>
              <IconSettings
                onClick={() =>
                  openModal(
                    <EndSetForm
                      activeType={activeType}
                      defaultValue={externalCounterSetting}
                      onSubmit={onChangeSetting}
                    />,
                    "",
                    ""
                  )
                }
              />
            </ActionIcon>
          </Flex>
          <ReportBox.Button
            size="md"
            onClick={() => {
              onSubmit && onSubmit(form.values.end)
              form.setValues({ end: "0" });
            }}
            disabled={disabled}
            type={"submit"}
            rightIcon={<IconDeviceFloppy size={'2rem'} />}
          >{t('실적 저장')}</ReportBox.Button>
        </Flex>
      </ReportBox.Section>
      <Flex gap={10} w={'100%'}>
        <ReportBox.ChangeNumberButton
          btnType="decrease"
          size="lg"
          color="grape.8"
          num={5}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <ReportBox.ChangeNumberButton
          btnType="decrease"
          size="lg"
          color="grape.6"
          num={1}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <ReportBox.Input
          w={'100%'}
          size="lg"
          disabled={disabled}
          {...form.getInputProps("end")}
          value={Number(form.values.end)}
        />
        <ReportBox.ChangeNumberButton
          size="lg"
          color="cyan.6"
          num={1}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <ReportBox.ChangeNumberButton
          size="lg"
          color="indigo.6"
          num={5}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
      </Flex>
    </ReportBox.Section>
  );
};
