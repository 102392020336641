import { InventoriesGet200Response, ProductionPlansGet200ResponseRowsInnerWorksInner } from '@sizlcorp/sizl-api-document/dist/models';
import { BaseText } from 'components/common/Text/baseText';
import { BaseTitle } from 'components/common/title/BaseTitle';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { setToLocaleString } from 'utils/unitMark';
import { WorkTable } from '../../../common/table/workTable';


const WorkInventories = Object.assign({}, {
    Text: BaseText,
    Title: BaseTitle,
    Table: WorkTable
});

interface WorkInventoriesComponentProps {
    workData: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined
    workInventoriesData: InventoriesGet200Response | undefined
}

export const WorkInventoriesComponent = (params: WorkInventoriesComponentProps) => {

    const { workData, workInventoriesData } = params
    const { t } = useTranslation();

    return (
        <>
            <WorkInventories.Table
                columns={[t("Grade"), t("로트명"), t("로트유효기한"), t("잔량")]}
                flex={[2, 2, 1, 1]}
                data={
                    workInventoriesData?.rows?.map((row) => {
                        return [
                            row.itemCode,
                            row.lot?.name ?? "-",
                            row.lot?.expiration
                                ? dayjs(row.lot?.expiration).format("YYYY-MM-DD")
                                : "-",
                            <div style={{ textAlign: "right" }}>{(setToLocaleString(row.quantity ?? '') + " " + row.unitText)}</div>,

                        ];
                    }) as string[][]
                }
            />
        </>
    );
};

export default WorkInventoriesComponent;