import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import useGetAuthToken from "hooks/useGetAuthToken";
import { useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { FooterInfo, footerInfoAtom } from "../../recoil/layoutAtom";
import { theme } from "../../styles/theme";

function Auth() {
  const firstInputRef = useRef<HTMLInputElement | null>(null);
  const formData = useRef<Map<string, any>>(new Map());
  const setFooterInfo = useSetRecoilState(footerInfoAtom);
  const loginCallback = ({
    centerName,
    managerName,
    workbayName,
  }: Partial<FooterInfo>) => {
    setFooterInfo({
      workbayName: workbayName ?? "",
      centerName: centerName ?? "",
      managerName: managerName ?? "",
    });
  };
  const onSignIn = async () => {
    const emailValue = formData.current.get("id");
    const passwordValue = formData.current.get("password");
    const validate = emailValue && passwordValue;

    if (validate) {
      setLoginInform({ identifier: emailValue, password: passwordValue });
    } else {
      alert("please enter email, password");
    }
  };
  const [loginInform, setLoginInform] = useState<{
    identifier: string;
    password: string;
  }>();

  const { isLoginLoading, loginRefetch } = useGetAuthToken(
    loginInform as { identifier: string; password: string },
    loginCallback
  );

  useEffect(() => {
    if (loginInform?.identifier && loginInform?.password) {
      loginRefetch();
    }
  }, [loginInform, loginRefetch]);

  return (
    <Container>
      {isLoginLoading ? (
        <LoadingSpinner />
      ) : (
        <LoginContainer>
          <LoginForm onSubmit={(e) => e.preventDefault()}>
            <InputBoxWrapper>
              <InputBox>
                <InputLabel>아이디</InputLabel>
                <Input
                  ref={firstInputRef}
                  type="text"
                  autoFocus
                  onChange={(e) => {
                    formData.current.set("id", e.target.value);
                  }}
                />
              </InputBox>

              <InputBox>
                <InputLabel>패스워드</InputLabel>
                <Input
                  type="password"
                  onChange={(e) => {
                    formData.current.set("password", e.target.value);
                  }}
                />
              </InputBox>
            </InputBoxWrapper>

            <LoginBtn type="submit" onClick={onSignIn}>
              로그인
            </LoginBtn>
          </LoginForm>
        </LoginContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease-in-out;
`;

const DarkBtn = styled.button`
  width: 10rem;
  height: 100%;

  color: white;
  font-size: 3.2rem;
  font-family: MBK CorpoS;
  font-weight: 400;
  letter-spacing: 0.032rem;
  word-wrap: break-word;
  border: 0.4rem solid transparent;
  border-image: linear-gradient(-10deg, #ffffff, #6c6a6a);
  border-image-slice: 1;

`;

const LoginContainer = styled(CenterContainer)`
  /* position: absolute; */
  /* left: 100%; */

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LoginForm = styled.form`
  width: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;

`;

const InputBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3rem;
`;
const InputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6.4rem;
  gap: 3rem;

`;
const InputLabel = styled.label`
  width: 30%;
  color: white;
  font-size: 3rem;
  font-family: MBK CorpoA;
  font-weight: 400;
  letter-spacing: 0.336rem;
  word-wrap: break-word;
  text-align: right;

`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  background: ${theme.colors?.gray?.[9]};
  padding: 1.2rem;
  font-size: 2.5rem;
  color: white;

`;

const LoginBtn = styled(DarkBtn)`
  width: 15rem;
  font-size: 2.2rem;
  padding: 2.2rem;
  background-color: black;
  transform: translateX(30%);
  
`;

// 회전 애니메이션을 정의
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// 스타일드 컴포넌트 생성
const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 0.8s linear infinite;
  margin: 20px auto;
`;

export default Auth;
