import { LocationsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { MasterInstance } from "../../../../instance/axios";

export type LocationOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = (keyword: string, query: any) =>
  MasterInstance.locationsFindPost({
    query,
    search: keyword ? keyword : "",
    searchFields: ["code", "name"],
  }).then((res: AxiosResponse<LocationsGet200ResponseRowsInner[]>) => res.data);

export const useLocationsAutoComplate = (
  focus = false,
  keyword = "",
  query = {},
  options?: {
    onSuccess: (data: LocationOption[]) => void;
  }
) => {
  return useQuery(
    ["locations-get-autocomplete", keyword],
    () => getAutoCompleteKeyword(keyword, query),
    {
      enabled: focus,
      select: (data: LocationsGet200ResponseRowsInner[]) =>
        data?.map((location) => {
          return {
            value: String(location.code),
            label: String(location.name),
            group: String(location.siteCode),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getLocationsByCode = (code: string | null) =>
  MasterInstance.locationsFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res: AxiosResponse<LocationsGet200ResponseRowsInner[]>) => res.data);

export const useLocationsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: LocationOption[]) => void;
  }
) => {
  return useQuery(
    ["locations-get-code", code],
    () => getLocationsByCode(code),
    {
      enabled: enabled ?? false,
      select: (data: LocationsGet200ResponseRowsInner[]) =>
        data?.map((location) => {
          return {
            value: String(location.code),
            label: String(location.name),
            group: String(location.siteCode),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};
