import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  redirectCondition: boolean;
  redirectPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ redirectCondition, redirectPath }) => {
  return redirectCondition ? <Navigate to={redirectPath} /> : <Outlet />;
};

export default ProtectedRoute;
