import { SitesGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { MasterInstance } from "../../../../instance/axios";

export type SitesOption = {
  value: string;
  label: string;
  // group?: string;
};
const getAutoCompleteKeyword = (keyword: string) =>
  MasterInstance.sitesGet({
    search: keyword,
    searchFields: ["name", "code"],
    populate: ["itemCategory"],
  });

export const useSitesAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: SitesOption[]) => void;
  }
) => {
  return useQuery(
    ["site-get-autocomplete-keyword", keyword],
    () => getAutoCompleteKeyword(keyword),
    {
      enabled: focus,
      select: (data: AxiosResponse<SitesGet200Response, any>) =>
        data?.data?.rows?.map((site) => {
          return {
            value: site.code as string,
            label: site.name as string,
            // group: String(item.itemCategoryId),
            // group: site.itemType,
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getItemByCode = (code: string | null) =>
  MasterInstance.sitesGet({
    ...(code && { search: code }),
    searchFields: ["code"],
  });

export const useSiteCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: SitesOption[]) => void;
  }
) => {
  return useQuery(["Site-get-code", code], () => getItemByCode(code), {
    enabled,
    select: (data: AxiosResponse<SitesGet200Response, any>) =>
      data?.data?.rows?.map((site) => {
        return {
          value: site?.code as string,
          label: site?.name as string,
          // group: String(item.itemCategoryId),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
