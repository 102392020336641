import { Flex } from "@mantine/core";

interface WorkContentProps {
    children: React.ReactNode;
}

export const RightContent = ({ children }: WorkContentProps) => {
    return <Flex
        style={{
            flex: '1 0 0'
        }}
        w={"50%"}
        className="right-content"
        direction={'column'}
        p={'0.625rem'}
        gap={'0.625rem'}
        align={'flex-start'}>
        {children}
    </Flex>;
};






