import { InventoriesGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import DefaultInstance from "instance/axios";
import { useQuery } from "react-query";

export const GET_INVENTORIES_QUERY_KEY = "get-inventories";

const getInventoriesFetch = (params?: any) => {

  if (params.query && params?.query?.$and) {
    const query: any = {};
    // query를 보내는 조건은 필터 검색을 할 때이다.
    query["query"] = JSON.stringify(params.query);

    return DefaultInstance.inventoriesGet({
      ...query,
      populate: ["lot", "item", "location"],
      page: params.activePage ?? 1,
      pageSize: 7,
      sort: params?.sort,
    });
  }

  return DefaultInstance.inventoriesGet({
    populate: ["lot", "item"],
    page: params.activePage ?? 1,
    pageSize: 7,
    sort: params?.sort,
  })

};

const useInventoriesGetQuery = (
  params?: any,
  options?: {
    onSuccess: (data: InventoriesGet200Response) => void;
  }
) => {
  return useQuery<AxiosResponse<InventoriesGet200Response, any[]>>({
    queryKey: params
      ? [
        GET_INVENTORIES_QUERY_KEY,
        JSON.stringify(params.query),
        params.activePage,
      ]
      : [GET_INVENTORIES_QUERY_KEY],
    //false 면 요청하지 않음
    enabled: !!params?.query,
    staleTime: 0,
    cacheTime: 0,
    queryFn: () =>
      params ? getInventoriesFetch(params) : getInventoriesFetch(),
    onSuccess: (res) => res.data && options?.onSuccess(res.data),
  });
};

export default useInventoriesGetQuery;