import { createMutationKeys } from "@lukemorales/query-key-factory";
import { ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { ProductionInstance } from "instance/axios";

export const WORK_LOGS_API = "WorkLogsApi";


export const workLogs = createMutationKeys(WORK_LOGS_API, {
    cancel: (param: ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest) => ({
        mutationKey: ['cancelInput', param],
        mutationFn: () => ProductionInstance.workLogsWorkLogIdCancelInputPut(param)
    })
});

export default workLogs;